<template>
    <div class="row">
        <div class="col-xl-3 mb-4" v-for="(item,i) in blogs.data" :key="i">
            <router-link :to="{name:'BlogDetail', params: {slug:item.slug} }"  >
                <div class="blogsBlock">
                    <img :src="item.image" class="img-fluid" alt="">
                    <h3>{{item.title}}</h3>
                    <div v-html="item.short_description"></div>
                    <span class="upload-date">{{ item.created_at }}</span>
                </div>
            </router-link>
        </div>
    
    </div>
</template>

<script>
import axios from 'axios'
import api from '@/services/api'
import Helper from "@/services/helper"
export default {
    name: 'BlogListing',
    data() {
        return {
            blogs:{
                data: [],
            },
            filterData:{
                limit: 16,
            },
        };      
    },
    mounted() {
        let app = this;
        app.renderData();
    },
    methods: {
        async renderData(){
            let app = this;
            Helper.showSpinner();
            await axios.post(api.getUrl('/search/posts'), app.filterData).then(function (response) {
                if(response.data.success){
                    let responseData = response.data.data;
                    app.blogs.data   = responseData.data;
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
    }
}
</script>