<template>
    <div>
        <section class="innerBanner">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadcrumb">
                            <ul>
                                <li>
                                    <router-link to="">
                                        <span class="icon-home"></span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="">Blog</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="pageTitle">
                            <h1>Blogs</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="pageWrap">
            <div class="container">
                
                <BlogListing />
            </div>
        </div>
    </div>
</template>
<script>
import BlogListing from '@/views/Blogs/partials/BlogListing'
export default {
    name: 'BlogList',
    components: { BlogListing },
    mounted() {
        document.title = 'Blogs';
    },
}
</script>
<style src="../../assets/css/blogs.css"></style>